import type { RegionalLanguageCode } from "@prisma/client";

import { type ClassValue, clsx } from "clsx";
import { customAlphabet } from "nanoid/non-secure";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { DEFAULT_LANGUAGE } from "~/utils/constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const generateId = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  7,
);

export const getLocale = (language: RegionalLanguageCode = DEFAULT_LANGUAGE) =>
  language.replace("_", "-");

export const log = (...messages: unknown[]) => {
  switch (process.env.NODE_ENV) {
    case "development":
      console.log(...messages);
      break;

    case "production":
    case "test":
  }
};

export const noop = () => {};

export const replaceMinusOneWithNull = (number: -1 | number) =>
  number === -1 ? null : number;

export const stringOrNullFromFormData = z
  .string()
  .optional()
  .transform((value) => (value == null || value === "null" ? null : value));

export const transformToArray = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => (Array.isArray(val) ? val : [val]), schema);
