import { Inquisitiveness, type RegionalLanguageCode } from "@prisma/client";

export const CLOUDINARY_CLOUD_NAME = "df3occ1ro";
export const CLOUDINARY_VIDEO_URL = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/video/upload`;
export const CLOUDINARY_IMAGE_URL = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload`;
export const NEW_ASSET_PLACEHOLDER_ID = "placeholder";

export const DEFAULT_LANGUAGE = "en_US" satisfies RegionalLanguageCode;
// Keep in sync with the `@default` in `schema.prisma`
export const DEFAULT_INQUISITIVENESS = Inquisitiveness.aiDecides;

export const FOLLOW_UP_QUESTIONS_FOR_INQUISITIVENESS = {
  aiDecides: [0, 5],
  deepDive: [3, 5],
  lightProbe: [1, 3],
  none: [0, 0],
  surfaceScan: [0, 1],
} satisfies Record<Inquisitiveness, [min: number, max: number]>;

export const HELICONE_SESSION_PATHS = {
  MAP_TRANSCRIPT_TO_FACETS: "/map-transcript-to-facets",
  WEB_INTERVIEW: "/web-interview",
} satisfies Record<string, `/${string}`>;
